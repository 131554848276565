import MDinput from '@/components/MDinput';
import { validatorEmail, validatorRequire } from '@/utils/validators';
import TextArea from '@/components/DSE/TextArea';
import DateTimePicker from '@/components/DSE/DateTimePicker';

export function getFormDefinition(vue) {
  return {
    id: {
      type: null,
      defaultValue: undefined
    },
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    code: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        required: true,
        caption: 'login.code'
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    email: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true,
        caption: 'common.email'
      },
      rules: [
        {
          validator: validatorRequire
        },
        {
          validator: validatorEmail
        }
      ]
    },
    description: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255
      }
    },
    jwtToken: {
      type: TextArea,
      hideOnCreate: true,
      defaultValue: null,
      props: {
        caption: 'deliveryAppUser.jwtToken',
        readonly: true
      }
    },
    tokenIssuedAt: {
      type: DateTimePicker,
      hideOnCreate: true,
      props: {
        caption: 'deliveryAppUser.tokenIssuedAt',
        readonly: true
      }
    },
    tokenValidUntil: {
      type: DateTimePicker,
      hideOnCreate: true,
      props: {
        caption: 'deliveryAppUser.tokenValidUntil',
        readonly: true
      }
    }
  };
}

export function createItemFromFormData(vue, form) {
  const user = {
    ...form,
    roles: []
  };
  if (form.id) {
    user['id'] = form.id;
  }
  return user;
}

export function createFormDataFromItem(vue, user) {
  return {
    ...user
  };
}
